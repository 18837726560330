import { useEffect, useState } from "react";
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import NavBar from "./components/navBar/NavBar";
import Cms from "./pages/cms/cms/Cms";
import Log from "./pages/cms/log/Log";
import HomeCompany from "./pages/company/homeCompany/HomeCompany";
import Services from "./pages/company/services/Services";
import Service from "./pages/company/service/Service";
import ContactUs from "./pages/general/contactUs/contactUs";
import Resources from "./pages/general/resources/Resources";
import HomeTalent from "./pages/talent/homeTalent/HomeTalent";
import FindAJob from "./pages/talent/findAJob/FindAJob";
import Context from "./contexts/Context";
import AboutUs from "./pages/general/aboutUs/AboutUs";
import Footer from "./components/footer/Footer";
import { getDataFB } from "./services/getData";
import { auth, fb } from "./services/firebaseConfig";
import ResetPassword from "./pages/cms/resetPassword/ResetPassword";
import AboutUsTeam from "./pages/general/aboutUsTeam/AboutUsTeam";
import Page404 from "./pages/general/page404/Page404";
import { addDeveloperByLink, themeEditor } from "./utils/themeEditor";
import Home from "./pages/general/home/Home";
import { clone, orderArray, remplaceTitle } from "./services/serviceslocal";
import { preloadImages } from "./utils/preloadImages";
import { c8e1afd3102260cebebfd515adb7 } from "./services/c8e1af-d310-226-0ceb-ebfd515adb7";
import TermsAndConditions from "./pages/general/termsAndConditions/TermsAndConditions";
import StaffingTermsAndConditions from "./pages/general/staffingTermsAndConditions/StaffingTermsAndConditions";
import TermsOfService from "./pages/general/termsOfService/TermsOfService";
import InterviewTips from "./pages/general/interviewTips/InterviewTips";
import { arrayServicesStatic, arrayTermStatic, contentBasicStatic } from "./static/siteContentsStatic";
import RecruitingProcess from "./pages/general/recruitingProcess/RecruitingProcess";
import SearchTest from "./pages/general/searchTest/SearchTest";

function App() {

    const [contentBasic, setContentBasic] = useState(contentBasicStatic)
    const  {pathname} = useLocation()
    const navigate = useNavigate()
    const [typeClient, setTypeClient] = useState("company")
    const [onPathname, setOnPathname] = useState(pathname)
    const [hiddenFooter, setHiddenFooter] = useState(true)
    
    const [siteContents, setSiteContents] = useState([])
    const [arrayServices, setArrayServices] = useState(arrayServicesStatic)
    const [arrayTerms, setArrayTerms] = useState(arrayTermStatic)
    const [allResources, setAllResources] = useState([])
    const [team, setTeam] = useState([])
    const [allJobs, setAllJobs] = useState([])
    const [lastDateSyncUsers, setLastDateSyncUsers] = useState()
    const [lastDateSyncJobs, setLastDateSyncJobs] = useState()
    const [files, setFiles] = useState([])    
    const [user, setUser] = useState(null)

    useEffect(() => {
        auth.onAuthStateChanged((user) => {setUser(user)})
        fb.analytics()        
        getDataFullDataFB();
    }, []);
    
    const getDataFullDataFB = async () => {
        const siteContents = await getDataFB("siteContents")
        setContentBasic(siteContents) 
        const arrayTerm = await getDataFB("siteContents/terms/term")
        setArrayTerms(arrayTerm)
        const arrayService = await getDataFB("siteContents/services/service")
        setArrayServices(arrayService)
        getDataUsers()
        getDataJobs()
        getDataResources()
    };

    const getDataUsers = async() =>{
        const users = await getDataFB("users")
        setTeam(users[0]?.data)
        setLastDateSyncUsers(users[0]?.lastSyncDate)
    }

    const getDataJobs = async() =>{
        const jobs = await getDataFB("jobs")
        setAllJobs(remplaceTitle(jobs[0]?.data.filter(item => item.TR1__Client_Description__c !== null)))
        setLastDateSyncJobs(jobs[0]?.lastSyncDate)
    }
    const getDataResources = async() =>{
        const resources = await getDataFB("resources")
        setAllResources(resources)
    }


    useEffect(() => {
        if(contentBasic.length > 0 && arrayServices.length > 0 && arrayTerms.length > 0){
            let indexService = contentBasic.findIndex(item => item.id === "services")
            let dataServices = contentBasic[indexService].data     
            let indexTerms = contentBasic.findIndex(item => item.id === "terms")
            let newData = clone(contentBasic)
            newData[indexService].data = [...dataServices, ...arrayServices]
            newData[indexTerms].data = [...arrayTerms]
            setSiteContents(orderArray(newData, "position"))
        }
    }, [contentBasic, arrayServices, arrayTerms])
        

    useEffect(() => {
      if(contentBasic.length > 0){
        c8e1afd3102260cebebfd515adb7()
        preloadImages(contentBasic).then(isLoaded => {})
      }
    }, [contentBasic])

    useEffect(() => {
        setOnPathname(pathname)
        const isCMS = (pathname.toLowerCase().includes("admin") || pathname.toLowerCase().includes("cms"))
        let isHidden = isCMS
        setHiddenFooter(isHidden)
        if(!isHidden){
            setTimeout(() => {
                addDeveloperByLink()
            }, 1000);
        }
        if(pathname.toString().substr(-1) === "/"){
            navigate(pathname.substring(0, pathname.length - 1))
        }
        
        if(pathname === "/jobs" || pathname.startsWith("/jobs/page") || pathname.startsWith("/job-industries") || pathname.startsWith("/job-fields")){
            return(navigate('/find-a-job'))
        }
        if(pathname.startsWith("/jobs/")){
            return(navigate(pathname.replace('/jobs/', '/find-a-job/jobs/')))
        }
        if(pathname.startsWith("/about-us/our-philosophy")){
            return(navigate('/about-us'))
        }
        if(pathname.startsWith("/about-us/who-we-are")){
            return(navigate('/about-us/meet-our-team'))
        }
        if(pathname.startsWith("/blog")){
            return(navigate('/resources'))
        }
        if(pathname.startsWith("/credits")){
            return(navigate('/about-us'))
        }
        if(pathname.startsWith("wp-content")){
            return(navigate('/about-us'))
        }
        if(pathname.startsWith("search-test")){
            return(navigate('/search-test'))
        }
    }, [pathname]);
    

    const navigateTo = (param) => {
        navigate(pathname + "/" + param);      
    }
    
    return (
        <div>
            <ThemeProvider theme={themeEditor()}>
                <Context.Provider value={{typeClient, setTypeClient, onPathname, setOnPathname, navigateTo, allResources, setAllResources, user, setTeam, team, setAllJobs, allJobs, siteContents, arrayServices, setArrayServices, arrayTerms, setArrayTerms, lastDateSyncUsers, setLastDateSyncUsers, lastDateSyncJobs, setLastDateSyncJobs, files, setFiles}}>
                    {siteContents.length > 0 &&
                        <div className="App" id="appLocal">
                            <NavBar onPathname={onPathname} />
                            <main id="main" className="minhCont">
                                <Routes>
                                    <Route path="*" element={<Page404 />} />
                                    <Route path="/" element={<Home/>} />
                                    <Route path="/company" element={<HomeCompany/>} />
                                    <Route path="/talent" element={<HomeTalent/>} />
                                    <Route path="/find-a-job" element={<FindAJob />} />
                                    <Route path="/find-a-job/:jobs" element={<FindAJob />} />
                                    <Route path="/find-a-job/:jobs/:job" element={<FindAJob />} />
                                    <Route path="/about-us" element={<AboutUs />} />
                                    <Route path="/about-us/meet-our-team" element={<AboutUsTeam />} />
                                    <Route path="/about-us/meet-our-team/:employee" element={<AboutUsTeam />} />
                                    <Route path="/services" element={<Services />} />
                                    <Route path="/services/:service" element={<Service />} />
                                    <Route path="resources" element={<Resources />} />
                                    <Route path="resources/:article" element={<Resources />} />
                                    <Route path="contact-us" element={<ContactUs />} />
                                    <Route path="terms-and-conditions" element={<TermsAndConditions />} />
                                    <Route path="staffing-terms-and-conditions" element={<StaffingTermsAndConditions />} />
                                    <Route path="terms-of-service" element={<TermsOfService />} />
                                    <Route path="interview-tips" element={<InterviewTips />} />
                                    <Route path="recruiting-process" element={<RecruitingProcess />} />
                                    <Route path="admin" element={user?<Cms/>:<Log />} />
                                    <Route path="admin/reset_password/action" element={<ResetPassword />} /> 
                                    <Route path="cms" element={user?<Cms/>:<Log/>} />
                                    <Route path="cms/:category" element={user?<Cms/>:<Log/>} />
                                    <Route path="cms/:category/:option" element={user?<Cms/>:<Log/>} />
                                    <Route path="cms/:category/:option/:article" element={user?<Cms/>:<Log/>} />   
                                    <Route path="/search-test" element={<SearchTest />} />
                                    <Route path="*" element={<Page404 />} />              
                                </Routes>
                            </main>
                            {!hiddenFooter && <Footer/>}
                        </div>
                    }
                </Context.Provider>
            </ThemeProvider>
        </div>
    );
}

export default App;
