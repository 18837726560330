import React, { useState, useContext } from 'react';
import './SearchTest.css';
import { Box, Button, TextField } from "@mui/material";
import Context from '../../../contexts/Context';

export default function SearchTest() {
    const { siteContents, allJobs } = useContext(Context);
    const [searchTerm, setSearchTerm] = useState('');

    const API_KEY = "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"; // Replace with your actual OpenAI API key

    async function fetchCompletion(prompt) {
        const openAIEndpoint = "https://api.openai.com/v1/chat/completions";
        const geminiEndpoint =
            "https://generativelanguage.googleapis.com/v1beta/models/gemini-pro:generateContent";
        const openAIKey = API_KEY; // Replace with OpenAI API Key
        const geminiKey = 'AIzaSyCE29zoWjZdih0rXhXwNBGUeJLim6zqJss'; // Replace with Gemini API Key
    
        try {
            // Attempt to fetch completion from OpenAI API
            const response = await fetch(openAIEndpoint, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${openAIKey}`,
                },
                body: JSON.stringify({
                    model: "o1-mini", // Replace with your preferred model
                    messages: [
                        {
                            role: "user",
                            content: prompt,
                        },
                    ],
                }),
            });
    
            const data = await response.json();
            if (response.ok) {
                console.log("OpenAI API Response:", data);
                return data.choices[0].message.content;
            } else {
                throw new Error(`OpenAI API failed with status: ${response.status}`);
            }
        } catch (error) {
            console.error("Error with OpenAI API, switching to Gemini:", error);
    
            try {
                // Attempt to fetch completion from Gemini API
                const response = await fetch(`${geminiEndpoint}?key=${geminiKey}`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        contents: [
                            {
                                parts: [
                                    {
                                        text: prompt,
                                    },
                                ],
                            },
                        ],
                    }),
                });
    
                const data = await response.json();
                if (response.ok) {
                    console.log("Gemini API Response:", data);
                    //return data.contents[0].parts[0].text; // Adjust based on Gemini response format
                } else {
                    throw new Error(`Gemini API failed with status: ${response.status}`);
                }
            } catch (geminiError) {
                console.error("Error with Gemini API:", geminiError);
                throw new Error("Both OpenAI and Gemini APIs failed.");
            }
        }
    }

    function simplifyJobData(jobsArray) {
        return jobsArray.map(job => ({
            TR1__Client_Description__c: job.TR1__Client_Description__c,
            Job_Public_Name__c: job.Job_Public_Name__c,
            TR1__City__c: job.TR1__City__c,
            TR1__Division__c: job.TR1__Division__c,
            TR1__Education_Requirements__c: job.TR1__Education_Requirements__c,
            TR1__Open_Date__c: job.TR1__Open_Date__c,
            TR1__State_Area__c: job.TR1__State_Area__c,
            Id: job.Id
        }));
    }

    const handleSearch = async () => {
        const simplifiedData = simplifyJobData(allJobs);
        const serializedData = JSON.stringify(simplifiedData);
        const prompt = `here is an object with some relevant data of job postings ${serializedData} can you return the id fields of the job positions that better fit the search ${searchTerm}. Return only the the ids separated by commas`;

        console.log("Generated Prompt:", prompt);

        await fetchCompletion(prompt);
    };

    return (
        <Box className="flex col w100 mt-10" sx={{ marginTop: "30px" }}>
            <Box className="flex row w50" sx={{ marginTop: "30px", marginRight: "auto", marginLeft: "auto", alignItems: "center", my: 1 }}>
                <TextField
                    className="w100"
                    label="Search Positions"
                    size="small"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                />
            </Box>
            <Box className="flex row w50" sx={{ marginRight: "auto", marginLeft: "auto", alignItems: "center", my: 1 }}>
                <Button 
                    onClick={handleSearch} 
                    sx={{ marginRight: "auto", marginLeft: "auto", alignItems: "center", my: 1 }}
                >
                    Search
                </Button>
            </Box>
        </Box>
    );
}
